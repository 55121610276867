import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';

const Clienti = () => {
    const [clienti, setClienti] = useState([]);
    const [settori, setSettori] = useState([]);
    const [open, setOpen] = useState(false);
    const [editingClient, setEditingClient] = useState(null);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [nomeSocieta, setNomeSocieta] = useState('');
    const [settoreMerceologico, setSettoreMerceologico] = useState('');
    const [nuovoSettore, setNuovoSettore] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchClienti();
                await fetchSettori();
            } catch (error) {
                console.error("Errore nel caricamento dei dati:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchClienti = async () => {
        const response = await axios.get('/wp-json/crm/v1/clienti');
        setClienti(response.data);
    };

    const fetchSettori = async () => {
        const response = await axios.get('/wp-json/crm/v1/settori');
        setSettori(response.data);
    };

    const handleOpen = () => {
        setEditingClient(null);
        setNome('');
        setEmail('');
        setTelefono('');
        setIndirizzo('');
        setNomeSocieta('');
        setSettoreMerceologico('');
        setNuovoSettore('');
        setErrorMessage('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        if (!editingClient && clienti.some((cliente) => cliente.email_cliente === email)) {
            setErrorMessage('Il cliente con questa email esiste già.');
            return;
        }

        try {
            if (nuovoSettore) {
                // Se un nuovo settore è stato inserito, lo creiamo
                const settoreResponse = await axios.post('/wp-json/crm/v1/settori', { nome_settore: nuovoSettore });
                setSettoreMerceologico(settoreResponse.data.nome_settore);
                fetchSettori(); // Aggiorna la lista dei settori con il nuovo settore
            }

            const clienteData = {
                nome_cliente: nome,
                email_cliente: email,
                telefono_cliente: telefono,
                indirizzo_cliente: indirizzo,
                nome_societa: nomeSocieta,
                settore_merceologico: settoreMerceologico || nuovoSettore,  // Salva il settore esistente o quello nuovo
            };

            if (editingClient) {
                await axios.put(`/wp-json/crm/v1/clienti/${editingClient.id}`, clienteData);
            } else {
                await axios.post('/wp-json/crm/v1/clienti', clienteData);
            }

            fetchClienti();
            handleClose();
        } catch (error) {
            console.error("Errore nel salvataggio del cliente:", error);
        }
    };

    const handleEdit = (cliente) => {
        setEditingClient(cliente);
        setNome(cliente.nome_cliente);
        setEmail(cliente.email_cliente);
        setTelefono(cliente.telefono_cliente);
        setIndirizzo(cliente.indirizzo_cliente);
        setNomeSocieta(cliente.nome_societa);
        setSettoreMerceologico(cliente.settore_merceologico || '');
        setNuovoSettore('');
        setErrorMessage('');
        setOpen(true);
    };

    const handleDelete = async (clienteId) => {
        try {
            await axios.delete(`/wp-json/crm/v1/clienti/${clienteId}`);
            fetchClienti();
        } catch (error) {
            console.error("Errore nella cancellazione del cliente:", error);
        }
    };

    const columns = [
        { field: 'nome_cliente', headerName: 'Nome', width: 150 },
        { field: 'email_cliente', headerName: 'Email', width: 200 },
        { field: 'telefono_cliente', headerName: 'Telefono', width: 150 },
        { field: 'indirizzo_cliente', headerName: 'Indirizzo', width: 250 },
        { field: 'nome_societa', headerName: 'Nome Società', width: 150 },
        {
            field: 'settore_merceologico',
            headerName: 'Settore Merceologico',
            width: 200,
        },
        {
            field: 'actions',
            headerName: 'Azioni',
            width: 200,
            renderCell: (params) => (
                <Box display="flex" gap={1}>
                    <Button variant="contained" color="primary" size="small" onClick={() => handleEdit(params.row)}>
                        Modifica
                    </Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => handleDelete(params.row.id)}>
                        Cancella
                    </Button>
                </Box>
            ),
        },
    ];

    if (isLoading) {
        return <Typography variant="h6">Caricamento in corso...</Typography>;
    }

    return (
        <Box sx={{ height: '100vh', width: '100%', backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h4" gutterBottom>Lista Clienti</Typography>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ marginBottom: 2 }}>
                Aggiungi Nuovo
            </Button>
            <DataGrid rows={clienti} columns={columns} pageSize={5} getRowId={(row) => row.id} autoHeight />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editingClient ? "Modifica Cliente" : "Aggiungi Cliente"}</DialogTitle>
                <DialogContent>
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                    <TextField autoFocus margin="dense" label="Nome" fullWidth value={nome} onChange={(e) => setNome(e.target.value)} />
                    <TextField margin="dense" label="Email" type="email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextField margin="dense" label="Telefono" fullWidth value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                    <TextField margin="dense" label="Indirizzo" fullWidth value={indirizzo} onChange={(e) => setIndirizzo(e.target.value)} />
                    <TextField margin="dense" label="Nome Società" fullWidth value={nomeSocieta} onChange={(e) => setNomeSocieta(e.target.value)} />
                    
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Settore Merceologico</InputLabel>
                        <Select
                            value={settoreMerceologico}
                            onChange={(e) => setSettoreMerceologico(e.target.value)}
                            label="Settore Merceologico"
                        >
                            {settori.map((settore) => (
                                <MenuItem key={settore.id} value={settore.nome_settore}>
                                    {settore.nome_settore}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Typography variant="body2" color="textSecondary">Oppure crea un nuovo settore:</Typography>
                    <TextField
                        margin="dense"
                        label="Nuovo Settore"
                        fullWidth
                        value={nuovoSettore}
                        onChange={(e) => setNuovoSettore(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annulla</Button>
                    <Button onClick={handleSave} color="primary">Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Clienti;
