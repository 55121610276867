// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Clienti from './components/Clienti';
import Settori from './components/Settori';
import Servizi from './components/Servizi';
import Opportunita from './components/Opportunita';
import NotFound from './components/NotFound';
import { Box } from '@mui/material';

function App() {
    // Ottieni il ruolo dell'utente (ad esempio, da props o da local storage in un'app più avanzata)
    const userRole = 'crm_admin'; // Modifica in base alla logica di autenticazione

    return (
        <Router>
            <Box sx={{ display: 'flex' }}>
                <Sidebar userRole={userRole} /> {/* Passa il ruolo dell'utente alla Sidebar */}
                
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/clienti" element={<Clienti />} />
                        <Route path="/settori" element={<Settori />} />
                        <Route path="/servizi" element={<Servizi />} />
                        <Route path="/opportunita" element={<Opportunita />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Box>
            </Box>
        </Router>
    );
}

export default App;
