import React from 'react';
import { Box, Typography } from '@mui/material';

const Dashboard = () => {
    return (
        <Box
            sx={{
                padding: 3,
                backgroundColor: '#F3F4F6',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h3" gutterBottom>
                Dashboard
            </Typography>
            <Typography variant="body1">
                Benvenuto nella Dashboard! Qui potrai visualizzare statistiche, grafici e altri dati.
            </Typography>
        </Box>
    );
};

export default Dashboard;
