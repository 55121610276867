import React from 'react';

function NotFound() {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>404 - Pagina non trovata</h1>
            <p>La pagina che stai cercando non esiste.</p>
        </div>
    );
}

export default NotFound;
