import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';

const Servizi = () => {
    const [servizi, setServizi] = useState([]);
    const [open, setOpen] = useState(false);
    const [nomeServizio, setNomeServizio] = useState('');
    const [editingServizio, setEditingServizio] = useState(null);

    useEffect(() => {
        fetchServizi();
    }, []);

    const fetchServizi = async () => {
        try {
            const response = await axios.get('/wp-json/crm/v1/servizi');
            setServizi(response.data);
        } catch (error) {
            console.error("Errore nel caricamento dei servizi:", error);
        }
    };

    const handleOpen = () => {
        setEditingServizio(null);
        setNomeServizio('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        try {
            if (editingServizio) {
                await axios.put(`/wp-json/crm/v1/servizi/${editingServizio.id}`, { nome_servizio: nomeServizio });
            } else {
                await axios.post('/wp-json/crm/v1/servizi', { nome_servizio: nomeServizio });
            }
            fetchServizi();
            handleClose();
        } catch (error) {
            console.error("Errore nel salvataggio del servizio:", error);
        }
    };

    const handleEdit = (servizio) => {
        setEditingServizio(servizio);
        setNomeServizio(servizio.nome_servizio);
        setOpen(true);
    };

    const handleDelete = async (servizioId) => {
        try {
            await axios.delete(`/wp-json/crm/v1/servizi/${servizioId}`);
            fetchServizi();
        } catch (error) {
            console.error("Errore nella cancellazione del servizio:", error);
        }
    };

    const columns = [
        { field: 'nome_servizio', headerName: 'Nome Servizio', width: 200 },
        {
            field: 'actions',
            headerName: 'Azioni',
            width: 150,
            renderCell: (params) => (
                <Box display="flex" gap={1}>
                    <Button variant="contained" color="primary" size="small" onClick={() => handleEdit(params.row)}>Modifica</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => handleDelete(params.row.id)}>Cancella</Button>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ height: '100vh', width: '100%', backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h4" gutterBottom>Gestione Servizi</Typography>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ marginBottom: 2 }}>
                Aggiungi Nuovo Servizio
            </Button>
            <DataGrid rows={servizi} columns={columns} pageSize={5} getRowId={(row) => row.id} autoHeight />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editingServizio ? "Modifica Servizio" : "Aggiungi Servizio"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nome Servizio"
                        type="text"
                        fullWidth
                        value={nomeServizio}
                        onChange={(e) => setNomeServizio(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annulla</Button>
                    <Button onClick={handleSave} color="primary">Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Servizi;
