import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, MenuItem } from '@mui/material';

const Opportunita = () => {
    const [opportunita, setOpportunita] = useState([]);
    const [clienti, setClienti] = useState([]);
    const [servizi, setServizi] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        nome_cliente: '',
        nome_servizio: '',
        descrizione: '',
        offerta_economica: '',
        documento: null,
        stato: 'in stallo'
    });
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        fetchOpportunita();
        fetchClienti();
        fetchServizi();
    }, []);

    const fetchOpportunita = async () => {
        try {
            const response = await axios.get('/wp-json/crm/v1/opportunita');
            setOpportunita(response.data);
            console.log("Dati opportunità ricevuti:", response.data);
        } catch (error) {
            console.error("Errore nel caricamento delle opportunità:", error);
        }
    };

    const fetchClienti = async () => {
        try {
            const response = await axios.get('/wp-json/crm/v1/clienti');
            setClienti(response.data);
            console.log("Dati clienti ricevuti:", response.data);
        } catch (error) {
            console.error("Errore nel caricamento dei clienti:", error);
        }
    };

    const fetchServizi = async () => {
        try {
            const response = await axios.get('/wp-json/crm/v1/servizi');
            setServizi(response.data);
            console.log("Dati servizi ricevuti:", response.data);
        } catch (error) {
            console.error("Errore nel caricamento dei servizi:", error);
        }
    };

    const handleOpen = () => {
        setFormData({
            nome_cliente: '',
            nome_servizio: '',
            descrizione: '',
            offerta_economica: '',
            documento: null,
            stato: 'in stallo'
        });
        setEditingId(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditingId(null);
    };

    const handleSave = async () => {
        const form = new FormData();
        Object.keys(formData).forEach((key) => {
            form.append(key, formData[key]);
        });

        console.log("Payload per l'invio:", formData);

        try {
            if (editingId) {
                console.log(`Modifica dell'opportunità con ID: ${editingId}`);
                form.append('id', editingId);  // Aggiunge l'ID al payload per l'update
                await axios.post('/wp-json/crm/v1/opportunita/update', form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                console.log("Creazione di una nuova opportunità");
                await axios.post('/wp-json/crm/v1/opportunita', form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            fetchOpportunita();
            handleClose();
        } catch (error) {
            console.error("Errore nel salvataggio dell'opportunità:", error);
        }
    };

    const handleEdit = (row) => {
        console.log("Dati dell'opportunità da modificare:", row);
        setFormData({
            nome_cliente: row.nome_cliente,
            nome_servizio: row.nome_servizio,
            descrizione: row.descrizione,
            offerta_economica: row.offerta_economica,
            documento: null,
            stato: row.stato
        });
        setEditingId(row.id);
        setOpen(true);
    };

    const handleDelete = async (id) => {
        try {
            console.log(`Eliminazione dell'opportunità con ID: ${id}`);
            await axios.delete(`/wp-json/crm/v1/opportunita/${id}`);
            fetchOpportunita();
        } catch (error) {
            console.error("Errore nella cancellazione dell'opportunità:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            documento: e.target.files[0]
        }));
        console.log("File selezionato:", e.target.files[0]);
    };

    const columns = [
        { field: 'nome_cliente', headerName: 'Cliente', width: 200 },
        { field: 'nome_servizio', headerName: 'Servizio', width: 200 },
        { field: 'descrizione', headerName: 'Descrizione', width: 300 },
        { field: 'offerta_economica', headerName: 'Offerta (€)', width: 150 },
        {
            field: 'stato',
            headerName: 'Stato',
            width: 150,
            renderCell: (params) => (
                <Box
                    sx={{
                        backgroundColor: params.value === 'vinta' ? 'green' : params.value === 'persa' ? 'red' : 'yellow',
                        color: 'white',
                        borderRadius: 1,
                        padding: '4px',
                        textAlign: 'center',
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: 'Azioni',
            width: 200,
            renderCell: (params) => (
                <>
                    <Button onClick={() => handleEdit(params.row)} color="primary">Modifica</Button>
                    <Button onClick={() => handleDelete(params.row.id)} color="secondary">Elimina</Button>
                </>
            ),
        },
    ];

    return (
        <Box sx={{ height: '100vh', width: '100%', backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h4" gutterBottom>Gestione Opportunità</Typography>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ marginBottom: 2 }}>
                Aggiungi Nuova Opportunità
            </Button>
            <DataGrid rows={opportunita} columns={columns} pageSize={5} getRowId={(row) => row.id} autoHeight />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editingId ? 'Modifica Opportunità' : 'Aggiungi Opportunità'}</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label="Cliente"
                        name="nome_cliente"
                        value={formData.nome_cliente}
                        onChange={handleChange}
                        fullWidth
                    >
                        <MenuItem value="">Seleziona cliente</MenuItem>
                        {clienti.map((cliente) => (
                            <MenuItem key={cliente.id} value={cliente.nome_cliente}>{cliente.nome_cliente}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Servizio"
                        name="nome_servizio"
                        value={formData.nome_servizio}
                        onChange={handleChange}
                        fullWidth
                        style={{ marginTop: '15px' }}
                    >
                        <MenuItem value="">Seleziona servizio</MenuItem>
                        {servizi.map((servizio) => (
                            <MenuItem key={servizio.id} value={servizio.nome_servizio}>{servizio.nome_servizio}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Descrizione"
                        name="descrizione"
                        value={formData.descrizione}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={3}
                        style={{ marginTop: '15px' }}
                    />
                    <TextField
                        label="Offerta Economica (€)"
                        name="offerta_economica"
                        type="number"
                        value={formData.offerta_economica}
                        onChange={handleChange}
                        fullWidth
                        style={{ marginTop: '15px' }}
                    />
                    <TextField
                        select
                        label="Stato"
                        name="stato"
                        value={formData.stato}
                        onChange={handleChange}
                        fullWidth
                        style={{ marginTop: '15px' }}
                    >
                        <MenuItem value="in stallo">In Stallo</MenuItem>
                        <MenuItem value="vinta">Vinta</MenuItem>
                        <MenuItem value="persa">Persa</MenuItem>
                    </TextField>
                    <input type="file" name="documento" onChange={handleFileChange} style={{ marginTop: '15px' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annulla</Button>
                    <Button onClick={handleSave} color="primary">Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Opportunita;
