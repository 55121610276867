import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';

const Settori = () => {
    const [settori, setSettori] = useState([]);
    const [open, setOpen] = useState(false);
    const [editingSettore, setEditingSettore] = useState(null);
    const [nomeSettore, setNomeSettore] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchSettori();
    }, []);

    // Funzione per caricare la lista dei settori
    const fetchSettori = async () => {
        try {
            const response = await axios.get('/wp-json/crm/v1/settori');
            setSettori(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error("Errore nel caricamento dei settori:", error);
            setErrorMessage("Errore nel caricamento dei settori. Riprova più tardi.");
        }
    };

    // Funzione per aprire il dialog per aggiungere un nuovo settore
    const handleOpen = () => {
        setEditingSettore(null);
        setNomeSettore('');
        setErrorMessage('');
        setOpen(true);
    };

    // Funzione per chiudere il dialog
    const handleClose = () => {
        setOpen(false);
        setNomeSettore(''); // Pulisce il campo quando il dialog viene chiuso
        setErrorMessage('');
    };

    // Funzione per salvare il settore (aggiunta o modifica)
    const handleSave = async () => {
        if (!nomeSettore) {
            setErrorMessage('Il nome del settore è obbligatorio.');
            return;
        }

        try {
            if (editingSettore) {
                // Modifica settore esistente
                await axios.put(`/wp-json/crm/v1/settori/${editingSettore.id}`, {
                    nome_settore: nomeSettore
                });
            } else {
                // Aggiunta di un nuovo settore
                await axios.post('/wp-json/crm/v1/settori', {
                    nome_settore: nomeSettore
                });
            }
            fetchSettori(); // Aggiorna la lista dei settori
            handleClose();
        } catch (error) {
            console.error("Errore nel salvataggio del settore:", error);
            setErrorMessage("Errore nel salvataggio del settore. Riprova.");
        }
    };

    // Funzione per aprire il dialog di modifica con i dati del settore selezionato
    const handleEdit = (settore) => {
        setEditingSettore(settore);
        setNomeSettore(settore.nome_settore);
        setErrorMessage('');
        setOpen(true);
    };

    // Funzione per cancellare un settore
    const handleDelete = async (settoreId) => {
        try {
            await axios.delete(`/wp-json/crm/v1/settori/${settoreId}`);
            fetchSettori(); // Aggiorna la lista dei settori
        } catch (error) {
            console.error("Errore nella cancellazione del settore:", error);
            setErrorMessage("Errore nella cancellazione del settore. Riprova.");
        }
    };

    // Colonne della tabella
    const columns = [
        { field: 'nome_settore', headerName: 'Nome Settore', width: 300 },
        {
            field: 'actions',
            headerName: 'Azioni',
            width: 200,
            renderCell: (params) => (
                <Box display="flex" gap={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleEdit(params.row)}
                    >
                        Modifica
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Cancella
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ height: '100vh', width: '100%', backgroundColor: '#fff', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h4" gutterBottom>Gestione Settori Merceologici</Typography>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ marginBottom: 2 }}>
                Aggiungi Nuovo Settore
            </Button>
            <DataGrid rows={settori} columns={columns} pageSize={5} getRowId={(row) => row.id} autoHeight />

            {/* Dialog per aggiungere o modificare settore */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editingSettore ? "Modifica Settore" : "Aggiungi Settore"}</DialogTitle>
                <DialogContent>
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nome Settore"
                        type="text"
                        fullWidth
                        value={nomeSettore}
                        onChange={(e) => setNomeSettore(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Annulla</Button>
                    <Button onClick={handleSave} color="primary">Salva</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Settori;
