import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Box } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ userRole }) => {
    const navigate = useNavigate();
    const drawerWidth = 240;
    const [openImpostazioni, setOpenImpostazioni] = useState(false);

    const handleImpostazioniClick = () => {
        setOpenImpostazioni(!openImpostazioni);
    };

    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
        { text: 'Lista Clienti', icon: <PeopleIcon />, path: '/clienti' },
        { text: 'Opportunità', icon: <WorkOutlineIcon />, path: '/opportunita' }, // Nuova voce Opportunità
    ];

    // Aggiungi "Operatori" per l'Admin come voce principale
    if (userRole === 'crm_admin') {
        menuItems.push({ text: 'Operatori', icon: <PersonIcon />, path: '/operatori' });
    }

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: '#111827',
                    color: '#fff',
                },
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <img src="/wp-content/uploads/logo.png" alt="Logo" style={{ maxHeight: '60px' }} />
            </Box>
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        onClick={() => navigate(item.path)}
                        sx={{
                            color: '#E5E7EB',
                            '&:hover': {
                                backgroundColor: '#374151',
                            },
                        }}
                    >
                        <ListItemIcon sx={{ color: '#9CA3AF' }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}

                {/* Voce di menu Impostazioni */}
                <ListItem
                    button
                    onClick={handleImpostazioniClick}
                    sx={{
                        color: '#E5E7EB',
                        '&:hover': {
                            backgroundColor: '#374151',
                        },
                    }}
                >
                    <ListItemIcon sx={{ color: '#9CA3AF' }}>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Impostazioni" />
                    {openImpostazioni ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                {/* Sottomenu per Settori Merceologici e Servizi sotto Impostazioni */}
                <Collapse in={openImpostazioni} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => navigate('/settori')}
                            sx={{
                                pl: 4,
                                color: '#E5E7EB',
                                '&:hover': {
                                    backgroundColor: '#374151',
                                },
                            }}
                        >
                            <ListItemIcon sx={{ color: '#9CA3AF' }}>
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settori Merceologici" />
                        </ListItem>

                        {/* Aggiunta voce "Servizi" nel sottomenu di Impostazioni per l'Admin */}
                        {userRole === 'crm_admin' && (
                            <ListItem
                                button
                                onClick={() => navigate('/servizi')}
                                sx={{
                                    pl: 4,
                                    color: '#E5E7EB',
                                    '&:hover': {
                                        backgroundColor: '#374151',
                                    },
                                }}
                            >
                                <ListItemIcon sx={{ color: '#9CA3AF' }}>
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Servizi" />
                            </ListItem>
                        )}
                    </List>
                </Collapse>
            </List>
        </Drawer>
    );
};

export default Sidebar;
